<template>
  <div>
    <h1>404</h1>
    <p>Nothing could be found here...</p>
  </div>
</template>

<script>
export default {
  name: "NotFoundPage",
};
</script>

<style scoped>
</style>
